const config = {
  apiGateway: {
    REGION: 'ap-southeast-2',
    INTERNAL_API_URL: 'https://86g17agi12.execute-api.ap-southeast-2.amazonaws.com/prod',
    TARGET_API_URL: 'https://90n28q7svc.execute-api.ap-southeast-2.amazonaws.com/api',
    TEAMS_APP_URL: 'https://teams.nsr.forwoodsafety.com/teams',
    QS_DASHBOARD_URL: '',
    CLIENT_TIMEZONE: 'Australia/Sydney'
  },
  reactApp: {
    VERSION: 'targets-1.6.0',
    HOSTNAME: 'https://targets.nsr.forwoodsafety.com'
  }
};

export default config;